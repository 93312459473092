<template>
  <main>
    <section class="bg-gray-medium py-16">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-7/10 tablet:mx-auto px-4">
            <header-h2
              :content="$t('404.header')"
              color="black"
              casing="uppercase"
              family="header"
              align="center"
            />
            <p class="error-page-text text-center" v-html="$t('404.text')" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Error',
  // eslint-disable-next-line vue/require-prop-types
  props: ['error'],
  layout: 'default',
  asyncData(context) {
    context.redirect('/')
  }
}
</script>

<style lang="scss">
.error-page-text a {
  font-weight: bold;
  text-decoration: underline;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}
</style>
